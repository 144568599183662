<template>
  <!-- <nav>
    <router-link to="/">Home</router-link>
    <router-link to="/dashboard">Dashboard</router-link>
    <router-link to="/register">Register</router-link>
    <router-link to="/sign-in">Sign In</router-link>
    <button @click="handleSignOut" v-if="isLoggedIn">Sign Out</button>
  </nav> -->
  <router-view/>
</template>

<script setup>
    import { onMounted, ref } from "vue";
    import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
    import { useRouter } from 'vue-router'

    const isLoggedIn = ref(false);
    const router = useRouter()

    let auth;

    onMounted (() =>{
        auth = getAuth();
        onAuthStateChanged (auth, (user) => {
            if (user) { 
                isLoggedIn.value = true;
            } else {
                isLoggedIn.value = false;
            }
        });
    });
    const handleSignOut = () => {
        signOut(auth).then(() => {
            router.push("/");
        })
    }
</script>

<style lang="scss">
    @import 'assets/css/index.css';
    @import '~bootstrap/dist/css/bootstrap.min.css';
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Fredoka+One');
    @import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,600,0,0');
    @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i');
    @import url('https://unpkg.com/boxicons@2.1.2/css/boxicons.min.css');
    @import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css');
</style>
