import { createRouter, createWebHistory } from 'vue-router'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import ("../views/HomeView.vue")
  },
  {
    path: '/register',
    name: 'register',
    component: () => import ("../views/RegisterView.vue")
  },
  {
    path: '/sign-in',
    name: 'signIn',
    component: () => import ("../views/SignInView.vue")
  },
  {
    path: '/email-verification',
    name: 'verify',
    component: () => import ("../views/EmailVerificationView.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/subscription-expired',
    name: 'expired',
    component: () => import ("../views/SubscriptionExpiredView.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/terms',
    name: 'terms',
    target: "_blank",
    component: () => import ("../views/TermsView.vue")
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import ("../views/PrivacyView.vue")
  },    
  {
    path: '/dashboard/:mode',
    name: 'dashboard',
    component: () => import ("../views/DashboardView.vue"),
    props: (route) => ({ 
        exchangeId: route.query.exchangeId,
        currentPage: route.query.currentPage,
        cardFreq: route.query.cardFreq,
        sortBy: route.query.sortBy,
        sortDir: route.query.sortDir,
        filterBy: route.query.filterBy,
        show: route.query.show
    }),  
    meta: {
        requiresAuth: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'pageNotFound',
    component: () => import ("../views/PageNotFound.vue"),
  },

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

const getCurrentUser = () => {
    return new Promise ((resolve, reject) => {
        const removeListener = onAuthStateChanged(
            getAuth(),
            (user) => {
                removeListener();
                resolve(user);
            },
            reject
        );
    });
};

router.beforeEach (async (to, from, next) => {
    // console.log("to: ", to);
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (await getCurrentUser()) {
            getAuth();
            next();
        } else {
            // alert("you dont have access!");
            next("/");
        }
    } else {
        next();
    }
})

export default router
