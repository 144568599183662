import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'


import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "vue3-loading-skeleton/dist/style.css";


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDBOhLjUMYVkKs2h94RCEf8A2yBv-TS9ZY",
  authDomain: "coinproof.firebaseapp.com",
  projectId: "coinproof",
  storageBucket: "coinproof.appspot.com",
  messagingSenderId: "863876726529",
  appId: "1:863876726529:web:1e9cfdf388dac4205d2500",
  measurementId: "G-CQG5PZ0VSN"
};

initializeApp(firebaseConfig);

createApp(App).use(store).use(router).mount('#app')